import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppConfigProvider, useConfigurationProvider } from "../../../../services/soft-cache-service";

const PackageDetailConfigContext = React.createContext();

const SOLUTION_ID = "package";
const DETAIL_PAGE_CONFIG = "detailPage";

const initialState = {
  additionalLocationCategories: [],
  deliveryIdentifier: "delivered",
  isLoading: true,
  imageAttributes: ["image_path"],
  imageBucket: "xemelgo-customer-images",
  itemAttributes: ["id", "identifier"],
  locationCategories: [],
  detailCard: {
    attributes: []
  },
  locationIdentifierToStatusColorMap: {},
  locationHistoryTable: {
    attributes: []
  },
  recipientProperty: null,
  // sud todo change :)
  useV2: true,
  packageStateToDisplayedStatusMap: {}
};

export const usePackageDetailConfigContext = () => {
  return useContext(PackageDetailConfigContext);
};

export const PackageDetailConfigContextProvider = ({ children }) => {
  const fullConfig = useConfigurationProvider();
  const packageDetailConfigProvider = useAppConfigProvider(SOLUTION_ID);
  const detailConfig = packageDetailConfigProvider.getValue(DETAIL_PAGE_CONFIG, "object") || {};

  const [isLoading, setIsLoading] = useState(initialState.isLoading);
  const [itemAttributes, setItemAttributes] = useState(initialState.itemAttributes);
  const [imageBucket, setImageBucket] = useState(initialState.imageBucket);
  const [imageAttributes, setImageAttributes] = useState(initialState.imageAttributes);
  const [locationCategories, setLocationCategories] = useState(initialState.locationCategories);
  const [detailCard, setDetailCard] = useState(initialState.detailCard);
  const [locationIdentifierToStatusColorMap, setLocationIdentifierToStatusColorMap] = useState(
    initialState.locationIdentifierToStatusColorMap
  );
  const [locationHistoryTable, setLocationHistoryTable] = useState(initialState.locationHistoryTable);
  const [recipientProperty, setRecipientProperty] = useState(initialState.recipientProperty);
  const [deliveryIdentifier, setDeliveryIdentifier] = useState(initialState.deliveryIdentifier);
  const [additionalLocationCategories, setAdditionalLocationCategories] = useState(
    initialState.additionalLocationCategories
  );
  const [useV2, setUseV2] = useState(initialState.useV2);
  const [packageStateToDisplayedStatusMap, setPackageStateToDisplayedStatusMap] = useState(
    initialState.packageStateToDisplayedStatusMap
  );

  useEffect(() => {
    setIsLoading(true);
    const {
      itemAttributes: newItemAttributes,
      imageAttributes: newImageAttributes,
      detailCard: newDetailCard,
      locationCategories: newLocationCategories,
      locationIdentifierToStatusColorMap: newLocationIdentifierToStatusColorMap,
      locationHistoryTable: newLocationHistoryTable,
      recipientProperty: newRecipientProperty,
      deliveryIdentifier: newDeliveryIdentifier,
      additionalLocationCategories: newAdditionalLocationCategories,
      useV2: newUseV2,
      packageStateToDisplayedStatusMap: newPackageStateToDisplayedStatusMap
    } = detailConfig || {};
    setImageAttributes(newImageAttributes || initialState.imageAttributes);
    setItemAttributes(newItemAttributes?.length ? newItemAttributes : initialState.itemAttributes);
    setDetailCard(newDetailCard && newDetailCard.attributes ? newDetailCard : initialState.detailCard);
    setLocationCategories(newLocationCategories || initialState.locationCategories);
    setLocationIdentifierToStatusColorMap(
      newLocationIdentifierToStatusColorMap || initialState.locationIdentifierToStatusColorMap
    );
    setLocationHistoryTable(
      newLocationHistoryTable && newLocationHistoryTable.attributes
        ? newLocationHistoryTable
        : initialState.locationHistoryTable
    );
    setRecipientProperty(newRecipientProperty);
    setDeliveryIdentifier(newDeliveryIdentifier);
    setAdditionalLocationCategories(newAdditionalLocationCategories);
    setUseV2(newUseV2 ?? initialState.useV2);
    setPackageStateToDisplayedStatusMap(
      newPackageStateToDisplayedStatusMap || initialState.packageStateToDisplayedStatusMap
    );
    setIsLoading(false);
  }, [detailConfig]);

  useEffect(() => {
    const newImageBucket = fullConfig?.config?.infrastructure?.imageStorage?.BUCKET;
    setImageBucket(newImageBucket);
  }, [fullConfig]);

  return (
    <PackageDetailConfigContext.Provider
      value={{
        isLoading,
        imageAttributes,
        imageBucket,
        itemAttributes,
        detailCard,
        locationCategories,
        locationIdentifierToStatusColorMap,
        locationHistoryTable,
        recipientProperty,
        deliveryIdentifier,
        additionalLocationCategories,
        useV2,
        packageStateToDisplayedStatusMap
      }}
    >
      {children}
    </PackageDetailConfigContext.Provider>
  );
};

PackageDetailConfigContextProvider.defaultProps = {
  children: null
};

PackageDetailConfigContextProvider.propTypes = {
  children: PropTypes.element
};
