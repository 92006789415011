import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppConfigProvider, useConfigurationProvider } from "../../../../services/soft-cache-service";

const PackageOrderDetailConfigContext = React.createContext();

const SOLUTION_ID = "package";
const DETAIL_PAGE_CONFIG = "packageOrderDetailPage";

const initialState = {
  isLoading: true,
  imageAttributes: ["image_path"],
  imageBucket: "xemelgo-customer-images",
  orderAttributes: ["id", "identifier"],
  detailCard: {
    orderAttributes: []
  },
  overallStatusTable: {
    attributes: []
  },
  packageTable: {
    attributes: []
  },
  transferOrderStatusToDisplayedStatusMap: {},
  packageStateToDisplayedStatusMap: {},
  pdfExportEnabled: false
};

export const usePackageOrderDetailConfigContext = () => {
  return useContext(PackageOrderDetailConfigContext);
};

export const PackageOrderDetailConfigContextProvider = ({ children }) => {
  const fullConfig = useConfigurationProvider();
  const packageOrderDetailConfigProvider = useAppConfigProvider(SOLUTION_ID);
  const detailConfig = packageOrderDetailConfigProvider.getValue(DETAIL_PAGE_CONFIG, "object") || {};
  const [isLoading, setIsLoading] = useState(initialState.isLoading);
  const [imageBucket, setImageBucket] = useState(initialState.imageBucket);
  const [imageAttributes, setImageAttributes] = useState(initialState.imageAttributes);
  const [orderAttributes, setOrderAttributes] = useState(initialState.orderAttributes);
  const [detailCard, setDetailCard] = useState(initialState.detailCard);
  const [overallStatusTable, setOverallStatusTable] = useState(initialState.overallStatusTable);
  const [packageTable, setPackageTable] = useState(initialState.packageTable);
  const [pdfExportEnabled, setPdfExportEnabled] = useState(initialState.pdfExportEnabled);
  const [packageStateToDisplayedStatusMap, setPackageStateToDisplayedStatusMap] = useState(
    initialState.packageStateToDisplayedStatusMap
  );
  const [transferOrderStatusToDisplayedStatusMap, setTransferOrderStatusToDisplayedStatusMap] = useState(
    initialState.transferOrderStatusToDisplayedStatusMap
  );

  useEffect(() => {
    setIsLoading(true);
    const {
      pdfExportEnabled: newPdfExportEnabled,
      imageAttributes: newImageAttributes,
      detailCard: newDetailCard,
      packageStateToDisplayedStatusMap: newPackageStateToDisplayedStatusMap,
      overallStatusTable: newOverallStatusTable,
      packageTable: newPackageTable
    } = detailConfig || {};
    const {
      transferOrderStatusToDisplayedStatusMap: newTransferOrderStatusToDisplayedStatusMap,
      orderAttributes: newOrderAttributes
    } = newDetailCard || {};
    setImageAttributes(newImageAttributes || initialState.imageAttributes);
    setOrderAttributes(newOrderAttributes);
    setDetailCard(newDetailCard);
    setPdfExportEnabled(newPdfExportEnabled);
    setOverallStatusTable(newOverallStatusTable);
    setPackageTable(newPackageTable);
    setTransferOrderStatusToDisplayedStatusMap(newTransferOrderStatusToDisplayedStatusMap);
    setPackageStateToDisplayedStatusMap(newPackageStateToDisplayedStatusMap);

    setIsLoading(false);
  }, [detailConfig]);

  useEffect(() => {
    const newImageBucket = fullConfig?.config?.infrastructure?.imageStorage?.BUCKET;
    setImageBucket(newImageBucket);
  }, [fullConfig]);

  return (
    <PackageOrderDetailConfigContext.Provider
      value={{
        isLoading,
        imageAttributes,
        imageBucket,
        orderAttributes,
        detailCard,
        pdfExportEnabled,
        packageTable,
        overallStatusTable,
        transferOrderStatusToDisplayedStatusMap,
        packageStateToDisplayedStatusMap
      }}
    >
      {children}
    </PackageOrderDetailConfigContext.Provider>
  );
};

PackageOrderDetailConfigContextProvider.defaultProps = {
  children: null
};

PackageOrderDetailConfigContextProvider.propTypes = {
  children: PropTypes.element
};
