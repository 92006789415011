import React from "react";
import { getFormattedDate } from "../../../../common/Utilities";
import ListTable from "../../../../components/ListTable/ListTable";
import Style from "./HistoryTable.module.css";

export const HistoryTable = ({ headers = [], data = [] }) => {
  console.log("header", headers);
  console.log("data", data);
  const getValue = (header, item) => {
    switch (header.type) {
      case "date":
        return getFormattedDate(item[header.id], header.format);
      default:
        return item[header.id];
    }
  };

  return (
    <div className={Style.history_table_container}>
      <div className={Style.table_title}>Tracking History</div>
      <div className={Style.table_container}>
        <ListTable
          header={headers}
          headerContainerClassName={Style.header_container}
          renderHeader={(header, index) => {
            return (
              <li
                key={`${header} ${index}`}
                className={Style.header_style}
              >
                {header.label}
              </li>
            );
          }}
          data={data}
          renderItem={(item, index) => {
            return (
              <li
                key={`${item}_${index}`}
                className={Style.event_row}
              >
                {headers.map((header) => {
                  const { id, additionalAttributes = [] } = header;
                  return (
                    <div
                      key={`${item}_${id}`}
                      className={Style.event_row_text}
                    >
                      <div>{getValue(header, item)}</div>
                      {additionalAttributes.map((attribute) => {
                        const { id, color } = attribute;
                        return (
                          !!item[id] && (
                            <div
                              style={{ color }}
                              key={id}
                            >
                              {item[id]}
                            </div>
                          )
                        );
                      })}
                    </div>
                  );
                })}
              </li>
            );
          }}
        />
      </div>
    </div>
  );
};
