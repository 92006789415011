import React, { useState } from "react";
import { getFormattedDate, getItemFromS3 } from "../../../../common/Utilities";
import ListTable from "../../../../components/ListTable/ListTable";
import Style from "./PackageTable.module.css";
import { ReactComponent as ExportIcon } from "../../../../assets/icons/export.svg";
import xemelgoStyle from "styles/variable";
import { getItemKeyForS3 } from "features/package-order-detail-feature/helpers/PackageOrderDetailFeatureHelper";
import { STATUS_OPTIONS } from "components/status-popup-component";
import OptionalLink from "components/optional-link/OptionalLink";
import { useHistory } from "react-router-dom";
import useMixpanelContext from "context/mixpanel-context";
import {
  PACKAGE_ORDER_DETAIL_PAGE_EVENT,
  PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS
} from "constants/mixpanel-constant/packageOrderDetailPage";

export const PackageTable = ({
  headers = [],
  data = [],
  reportLink = "",
  reportBucket,
  pdfExportEnabled,
  setPopupContent
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredPackageData, setFilteredPackageData] = useState([]);
  const { sendMixPanelEvent } = useMixpanelContext();
  const history = useHistory();

  const getValue = (header, item) => {
    switch (header.type) {
      case "date":
        return getFormattedDate(item[header.id], header.format);
      case "statusPill":
        return getStatusPill(item[header.id]);
      case "internalLink":
        const { openLinkInNewTab, path } = header.options || {};
        const { id } = item;
        const domain = history.location.pathname.split("/")[0];
        return (
          <OptionalLink
            active
            key={id}
            route={`${domain}${path}${id}`}
            target={openLinkInNewTab ? "_blank" : ""}
          >
            <span className={`${Style.table_item_text_clickable}`}>{item[header.id]}</span>
          </OptionalLink>
        );
      default:
        return item[header.id];
    }
  };

  const getStatusPill = (statusData) => {
    return (
      <div
        style={{ backgroundColor: statusData.color || xemelgoStyle.theme.APP_BLUE }}
        className={Style.status}
      >
        <p className={Style.status_text}>{statusData.label}</p>
      </div>
    );
  };

  const onSearchTextChange = (event) => {
    const newText = event.target.value;
    setSearchText(newText);
    const filteredPackageDataToUpdate = data.filter((eachPacakge) => {
      return eachPacakge.identifier.includes(newText.toUpperCase());
    });
    setFilteredPackageData(filteredPackageDataToUpdate);
  };

  const onExportPDF = async (e) => {
    e.preventDefault();

    if (!reportLink) {
      setPopupContent({
        status: STATUS_OPTIONS.ERROR,
        message: "Deliver report does not exist for this order."
      });
      return;
    }

    try {
      setPopupContent({
        status: STATUS_OPTIONS.LOADING,
        message: "Fetching deliver report......"
      });
      const key = getItemKeyForS3(reportLink);
      await getItemFromS3(reportBucket, key, true);
      setPopupContent({});
      sendMixPanelEvent(
        PACKAGE_ORDER_DETAIL_PAGE_EVENT,
        PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS.DELIVERY_REPORT_EXPORT_SUCCESS
      );
    } catch (err) {
      sendMixPanelEvent(
        PACKAGE_ORDER_DETAIL_PAGE_EVENT,
        PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS.DELIVERY_REPORT_EXPORT_FAILED
      );
      setPopupContent({
        status: STATUS_OPTIONS.ERROR,
        message: "Error fetching delivery report. Please try again or contact xemelgo support."
      });
    }
  };

  return (
    <div className={Style.history_table_container}>
      <div className={Style.table_title}>Associated Packages</div>
      <div className={Style.searchbar_pdfbutton_container}>
        <div className={Style.searchbar_container}>
          <span className={`fa fa-search ${Style.searchbar_icon} ${Style.searchbar_icon_offset}`} />
          <input
            onChange={onSearchTextChange}
            className={Style.searchbar}
            placeholder={"Search for a tracking number"}
          />
        </div>
        {pdfExportEnabled && (
          <button
            className={Style.pdfbutton_container}
            onClick={onExportPDF}
          >
            <ExportIcon
              color={xemelgoStyle.theme.APP_BLUE}
              className={Style.export_icon}
            />
            <span className={Style.pdfbutton_text}>Export</span>
          </button>
        )}
      </div>
      <div className={Style.table_container}>
        <ListTable
          header={headers}
          headerContainerClassName={Style.header_container}
          renderHeader={(header, index) => {
            return (
              <li
                key={`${header} ${index}`}
                className={Style.header_style}
              >
                {header.label}
              </li>
            );
          }}
          data={searchText === "" ? data : filteredPackageData}
          renderItem={(item, index) => {
            return (
              <li
                key={`${item}_${index}`}
                className={Style.event_row}
              >
                {headers.map((header) => {
                  const { id, additionalAttributes = [] } = header;
                  return (
                    <div
                      key={`${item}_${id}`}
                      className={Style.event_row_text}
                    >
                      {getValue(header, item)}
                      {additionalAttributes.map((attribute) => {
                        const { id, color } = attribute;
                        return (
                          !!item[id] && (
                            <div
                              style={{ color }}
                              key={id}
                            >
                              {item[id]}
                            </div>
                          )
                        );
                      })}
                    </div>
                  );
                })}
              </li>
            );
          }}
        />
      </div>
    </div>
  );
};
