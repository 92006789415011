import React, { useState, useEffect, useMemo } from "react";
import queryString from "query-string";
import { useXemelgoClient } from "../../services/xemelgo-service";
import usePackageOrderDetailConfigContext, {
  PackageOrderDetailConfigContextProvider
} from "./contexts/package-order-detail-config-context";
import { getLogo } from "../../common/Utilities";
import Style from "./PackageOrderDetailFeature.module.css";
import { ReactComponent as PackageIcon } from "../../assets/icons/package.svg";
import DetailScreen from "../../components/DetailScreen";
import PackageTable from "./components/package-table";
import OrderStatusTable from "./components/order-status-table";
import xemelgoStyle from "../../styles/variable";
import {
  getDetailScreenData,
  getOverallOrderStatusData,
  getPackageConnectionPayload,
  getPackageData
} from "./helpers/PackageOrderDetailFeatureHelper";
import CONFIGURATION from "../../configuration.json";
import StatusPopupComponent from "components/status-popup-component";
import { isEmpty } from "lodash";
import useMixpanelContext from "context/mixpanel-context";
import {
  PACKAGE_ORDER_DETAIL_PAGE_EVENT,
  PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS
} from "constants/mixpanel-constant/packageOrderDetailPage";

const REPORT_BUCKET = CONFIGURATION.s3_report_configuration.bucket_name;
const ERROR_POPUP_DURATION_MS = 5000;

export const PackageOrderDetailFeature = () => {
  const [transferClient] = useState(useXemelgoClient().getTransferClient());
  const [reportClient] = useState(useXemelgoClient().getReportClient());
  const [orderData, setOrderData] = useState({});
  const [reportLink, setReportLink] = useState(undefined);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupContent, setPopupContent] = useState({});
  const { sendMixPanelEvent } = useMixpanelContext();

  const {
    isLoading: isConfigLoading,
    imageAttributes,
    imageBucket,
    orderAttributes = [],
    pdfExportEnabled,
    overallStatusTable,
    packageTable,
    packageStateToDisplayedStatusMap,
    transferOrderStatusToDisplayedStatusMap
  } = usePackageOrderDetailConfigContext();

  const onLoad = async (orderId) => {
    const logo = await getLogo();
    const filteredOrderAttributeId = orderAttributes
      .filter((eachAttribute) => !eachAttribute.excludeFromQuery)
      .map((eachAttribute) => eachAttribute.id);

    const additionalQueryAttributesMap = {
      transferOrder: [...filteredOrderAttributeId]
    };
    const packageConnection = getPackageConnectionPayload(imageAttributes);
    const fetchedOrderData = await transferClient.getTransferOrdersByIds(
      [orderId],
      additionalQueryAttributesMap,
      packageConnection
    );

    const { identifier: orderIdentifier } = fetchedOrderData[0] || {};

    const reports = await reportClient.getReportByIdentifier(orderIdentifier);
    console.log("reports", reports);
    if (reports.length !== 0) {
      const { result = {} } = reports[0] || {};
      console.log("result", result);
      const { reportLink = undefined } = result;
      console.log("reportlink", reportLink);
      setReportLink(reportLink);
    }
    setOrderData(fetchedOrderData[0]);
    setImages([logo]);
    setLoading(false);
  };

  useEffect(() => {
    if (window.location.search && !isConfigLoading) {
      const values = queryString.parse(window.location.search);
      const { itemId: orderId } = values;
      sendMixPanelEvent(PACKAGE_ORDER_DETAIL_PAGE_EVENT, PACKAGE_ORDER_DETAIL_PAGE_EVENT_STEPS.ENTRY);
      onLoad(orderId);
    }
  }, [window.location.search, isConfigLoading]);

  // const fetchImages = async () => {
  //   const newImages = [];
  //   setImages(newImages);
  // };

  const orderStatusPillList = useMemo(() => {
    const { transfer_status } = orderData;
    return transfer_status ? [transferOrderStatusToDisplayedStatusMap[transfer_status]] : [];
  }, [orderData, transferOrderStatusToDisplayedStatusMap]);

  const detailScreenData = useMemo(() => {
    return getDetailScreenData(orderData, orderAttributes);
  }, [orderData, orderAttributes]);

  const overallOrderStatusTableData = useMemo(() => {
    return getOverallOrderStatusData(orderData, packageStateToDisplayedStatusMap);
  }, [orderData, packageStateToDisplayedStatusMap]);

  const packageTableData = useMemo(() => {
    return getPackageData(orderData, packageTable.attributes, packageStateToDisplayedStatusMap);
  });

  const bottomButtonComponent = () => {
    return null;
  };

  return (
    <>
      <DetailScreen
        title="Order Details"
        borderColor={xemelgoStyle.theme.PACKAGE_PRIMARY}
        iconBorderColor={xemelgoStyle.theme.PACKAGE_SECONDARY}
        data={detailScreenData}
        TitleIconComponent={() => {
          return (
            <PackageIcon
              width={25}
              height={25}
              style={{ color: xemelgoStyle.theme.PACKAGE_PRIMARY }}
            />
          );
        }}
        isLoading={isConfigLoading || loading}
        disableEdit
        images={images}
        statusList={orderStatusPillList}
        BottomButtonComponent={bottomButtonComponent}
      >
        {!(isConfigLoading || loading) && (
          <div>
            <OrderStatusTable
              headers={overallStatusTable.attributes}
              data={overallOrderStatusTableData}
            />

            <PackageTable
              headers={packageTable.attributes}
              data={packageTableData}
              reportLink={reportLink}
              reportBucket={REPORT_BUCKET}
              pdfExportEnabled={pdfExportEnabled}
              setPopupContent={setPopupContent}
            />
          </div>
        )}
      </DetailScreen>
      <div className={Style.status_popup}>
        <StatusPopupComponent
          showPopup={!isEmpty(popupContent)}
          message={popupContent.message || ""}
          status={popupContent.status}
          durationMs={ERROR_POPUP_DURATION_MS}
          closeOnClickOutside
          onPopupClose={() => {
            setPopupContent({});
          }}
        />
      </div>
    </>
  );
};

export default () => {
  return (
    <PackageOrderDetailConfigContextProvider>
      <PackageOrderDetailFeature />
    </PackageOrderDetailConfigContextProvider>
  );
};
